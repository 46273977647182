.alert_badge {
  line-height: 15px;
  text-transform: uppercase;
  padding: 0px 6px;
  font-weight: 500;
  margin-right: 5px;
  border-radius: 4px;
}

.ticket_badge {
  text-transform: uppercase;
  padding: 0px 4px;
  border-radius: 4px;
  color: #7b818a;
  background-color: #e4e4e4;
}
.status_container {
  line-height: 13px;
  padding-top: 0;
  padding-bottom: 0;
  .alert_status {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .status_label {
    font-size: 10px;
  }
}

.device_name {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  display: flex;
  p + p:before {
    content: ' | ';
    padding: 0 5px;
    color: #898f95;
  }
}

.create_date {
  text-align: right;
  padding-right: 7px;
  margin-bottom: 0px;
  font-size: 9px;
}

.arrow_forward {
  font-size: 10px !important;
}

.search_box {
  input {
    padding-bottom: 5px !important;
    padding-top: 5px !important;
  }
}

.alert_id {
  font-size: 12px;
  margin-right: 5px;
}

.tab_title_button {
  min-height: 50px;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 30%;
}

.ticket_icon {
  width: 15px;
  height: auto;
}

.new_alert_dot {
  position: absolute;
  left: 10px;
  top: 10px;
  span {
    min-width: 5px;
    height: 5px;
  }
}
