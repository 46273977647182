.global-alert-container {
  top: 0;
  position: relative;
  height: 100vh;
  z-index: 22;
  max-width: 250px;
  border-radius: 7px;
  box-shadow: 5px #f9f9f9;
  // border-left: 1px solid #e7e7e7;
  back .head-name {
    width: 50%;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    min-height: 40px;
    font-size: 14px;
    font-weight: 600;
    color: #9ea2a9;
    align-items: center;
    padding-top: 10px;
    :focus {
      color: #343b46;
    }
  }
  .badge-icon {
    position: absolute;
    left: 15%;
    top: 50%;
    .css-15wskh7-MuiBadge-badge {
      font-size: 10px;
      padding: 0 3px;
    }
  }
  .has-search {
    margin-right: 0px;
    .form-control {
      padding-left: 2rem;
    }

    .form-control-feedback {
      position: absolute;
      z-index: 2;
      display: block;
      width: 2rem;
      height: 2rem;
      line-height: 2.375rem;
      text-align: center;
      pointer-events: none;
      color: #aaa;
    }
  }
}
