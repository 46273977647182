.action-field {
  min-width: 200px;
  textarea,
  label,
  input {
    font-weight: 500;
    font-size: 14px;
    color: #7b818a;
  }
}
.ticket-grid-total {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  // color: #343b46;
}
.ticket-generation-card {
  overflow-x: auto;
  margin-top: 21px;
  border-radius: 10px;
}

.team-member-box {
  margin-left: 10px;
  width: 65%;
}
.team-mem-val {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #9ea2a9;
}
.ticket-category-box {
  width: 42%;
  margin-right: 10px;
}
.to-do-list-box {
  width: 58%;
  margin-left: 10px;
}
// .tab-design-ticket-category {
//   background: #4f46ba;
// }
.add-to-do-btn {
  background: #fff;
  border: none;
  margin-top: 23px;
  padding: 0px;
  &:hover {
    background: none;
    border: none;
  }
  .add-to-do-button {
    cursor: pointer;
  }
  .add-to-do {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #343b46;
    margin-left: 10px;
  }
}

.add-to-do-content {
  padding: 2px;
  margin-top: 22px;
  background: #ffffff;
  border: 1px solid #eaedf2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
}
.cat-active {
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 24px;
  // color: #343b46 !important;
}
.cat-inactive {
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 24px;
  // color: #9ea2a9 !important;
}
.grid-right-border {
  border-right: 1px solid #eaedf2;
}
.grid-bottm-border {
  border-bottom: 1px solid #eaedf2;
}
.padding-grid {
  padding: 10px 15px;
}
.search-member-field {
  height: 36px;
  input {
    padding: 9px 10px;
    font-size: 12px;
  }
  .search-team {
    height: 36px;
  }
}
.add-to-check-content {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #343b46;
}
.add-to-check-subcontent {
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #7b818a;
}
.add-to-check-no {
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #7b818a;
  margin-right: 8px;
}
.bottom-add-to-border {
  border-bottom: 1px solid #eaedf2;
}
.followup-card {
  height: 194px;
  overflow-y: auto;
}

.border-radius-team {
  border-radius: 10px;
}
.apply-filter {
  margin-top: 40px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  width: 40%;
  text-align: center;
  padding: 14px;
}
.select-ticket {
  // background: #ffffff;
  // border: 1px solid #eaedf2;
  border-radius: 4px;
  color: #7b818a;
  font-weight: 500;
  & > fieldset {
    border: none;
  }
}
.followup-textarea {
  padding: 10px;
}
.date-line-height {
  input {
    padding: 8.5px 14px !important;
  }
}
.back-link {
  line-height: 2.2;
  cursor: pointer;
  margin-right: 4%;
}
.generation-legend {
  width: 13px;
  height: 13px;
  margin: auto 2px;
  border-radius: 50%;
}

.generation-legend1 {
  width: 13px;
  height: 13px;
  margin: auto 2px;
}
