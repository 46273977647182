body {
  font-family: 'Poppins';
  background: #f9fafb;
}

header {
  z-index: 9 !important;
}
#root {
  z-index: 9 !important;
}
.footer-branding {
  margin-bottom: 15px;
}

.page-heading {
  font-weight: bold;
}

.project-subtitle {
  box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 5%);
  padding: 0px 5px;
  border-radius: 6px;
}

.global-alert-container {
  min-width: 280px;
}
/* (B) ATTACH BLINKING KEYFRAMES TO CONTAINER */
/* animation: name | time | iterations */
.blink-red {
  animation: blinking 1s infinite;
}

.alert-red {
  background-color: #fdeded;
}

.alert-grey {
  background-color: #d3d3d3;
}

.blink-green {
  animation: blinkingGreen 1s infinite;
}
.green-indicator {
  background: #019f79;
}

.red-indicator {
  background-color: #fa5e5e;
}

.read-all-text {
  text-align: right;
  padding: 0 10px;
  display: block;
  a {
    color: #3366ff;
    font-size: 15px;
    text-decoration: none;
  }
}

.voilet-outline-btn.disabled {
  color: #9ea2a9;
  border-color: #9ea2a9;
}

.custom-chip {
  font-size: 10px;
  line-height: 15px;
  font-weight: 500;
  color: #fff;
  padding: 0px 5px;
  border-radius: 5px;
  text-transform: uppercase;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.d-flex {
  display: flex;
}
.d-none {
  display: none;
}
.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.my-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}
.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
.px-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.d-flex {
  display: flex;
}

.p-1 {
  padding: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-3 {
  padding: 0.75rem !important;
}
.w-100 {
  width: 100% !important;
}
.w-75 {
  width: 75% !important;
}
.w-25 {
  width: 25% !important;
}
.w-65 {
  width: 65% !important;
}
.container {
  padding-left: 0px;
  padding-right: 0px;
  max-width: 100%;
}
.text-center {
  text-align: center !important;
}
.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.align-items-center {
  align-items: center;
}
.bg-danger {
  background-color: #fa5e5e !important;
}
.bg-warning {
  background-color: #f09205 !important;
}
.bg-success {
  background-color: #019f79 !important;
}
.bg-dark {
  background-color: #212529 !important;
}
.text-dark {
  color: #212529 !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.text-danger {
  --bs-text-opacity: 1;
  color: #dc3545 !important;
}
.text-success {
  --bs-text-opacity: 1;
  color: #198754 !important;
}
.text-light {
  --bs-text-opacity: 1;
  color: #ffffff !important;
}
.text-secondary {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}
hr {
  margin: 1rem 0;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
  height: 1px;
}

.status-label {
  padding: 1px 4px;
  border-radius: 3px;
  align-items: center;
  font-weight: 600;
  margin-right: 10px;
  color: #fff;
  display: inline-flex;
}

.common-status-badge {
  border: 1px solid;
  padding: 2px 10px;
  border-radius: 4px;
  align-items: center;
  display: inline-flex;
}

.common-status-badge-1 {
  padding: 2px 10px;
  border-radius: 4px;
  align-items: center;
  display: inline-flex;
}

.status-label-priority {
  padding: 1px 10px;
  border-radius: 3px;
  align-items: center;
  font-weight: 500;
  background-color: #f4f4f4;
  color: #7b818a;
  display: inline-flex;
}

#video_info {
  padding-top: 24px;
}

.gm-style-iw.gm-style-iw-c {
  padding: 8px;
}

.gm-style-iw.gm-style-iw-c .gm-style-iw-d {
  padding: 0px 13px 5px 0px;
  overflow: auto !important;
}
.gm-style-iw.gm-style-iw-c .gm-ui-hover-effect {
  height: 20px !important;
  width: 26px !important;
}
.gm-style-iw.gm-style-iw-c .gm-ui-hover-effect > span {
  background: #fff;
  height: 10px !important;
  width: 10px !important;
}
.swal2-container {
  z-index: 9999 !important;
}
.cursor-pointer {
  cursor: pointer;
}
.react-transform-component {
  width: 100% !important;
  height: 100% !important;
}

.react-transform-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.zoom-tools {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.cusor-pointer {
  cursor: pointer;
}

@keyframes blinking {
  0% {
    background-color: #ef5350;
  }
  /* ADD MORE KEYFRAMES IF YOU WANT
    50% {
      background-color: #55d66b;
      border: 5px solid #126620;
    } */
  100% {
    background-color: #fdeded;
  }
}

@keyframes blinkingGreen {
  from {
    background-color: #00d100;
  }
  50% {
    background-color: #007500;
  }
  to {
    background-color: #00d100;
  }
}
.gm-style {
  .gm-style-iw-d::-webkit-scrollbar-track,
  .gm-style-iw-d::-webkit-scrollbar-track-piece,
  .gm-style-iw-c,
  .gm-style-iw-tc::after {
    background: rgb(61, 60, 60);
  }
}

.datagrid-header {
  font-weight: 600;
  font-size: 12px;
  padding: 0;
}

.datagrid {
  padding: 0;
  line-height: 18px;
  justify-content: center;
}
.css-1dk38ps {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 24px;
  padding-bottom: 0px;
}
.css-1jliix {
  margin-bottom: 13px;
  margin-left: 20px;
  margin-right: 20px;
}
.css-1jliix {
  margin-bottom: 13px;
  margin-left: 11px;
  margin-right: 6px;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}
