.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 20%;
  }
}
.close-btn {
  min-width: 25px;
  padding: 0px;
  margin-top: 10px;
  border-radius: 50%;
  // color: #4f46ba !important;
}
.dialog-content {
  padding: 0px 24px;
  .content-body {
    min-height: 500px;
    max-width: 500px;
    .content-form {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
    .width-30 {
      width: 30%;
    }
    .width-70 {
      width: 70%;
    }
    .width-100 {
      width: 100%;
    }
  }
}
.add_contact_btn {
  border: 1px solid #4f46ba !important;
  color: #4f46ba !important;
  padding: 8px 14px;
}
.vms-traffic-road {
  width: 65px;
  height: 90%;
  margin: auto;
  align-self: center;
  position: relative;
}
.vms-traffic-body {
  background-color: #110e30;
  height: 100%;
  position: relative;
  border-radius: 10px;
  display: flex;
}
.vms-msg-head {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.vms-msg-table {
  // background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.vms-msg-table-row-color {
  color: #797d80;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
}
.vms-msg-table-head-color {
  color: #343b46;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}
.vms-msg-table-edit {
  display: flex;
  justify-content: center;
}
.table-width {
  width: 95%;
}

.vms-msg-table-edit-color {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #4f46ba;
  padding: 1px;
  min-width: 55px;
  border: 1px solid #4f46ba;
}
.border-bottom-table {
  border-bottom: 2px solid #eaedf2;
}
.border-left-table {
  border-left: 2px solid #eaedf2;
  border-bottom: 2px solid #eaedf2;
}
.padding-10 {
  padding: 10px;
  padding-left: 18px;
}
.message-slider {
  position: fixed;
  width: 1000px;
  height: 100vh;
  top: 0px;
  right: 0px;
  z-index: 99;
  background: #fff;
  box-shadow: -10px 0px 36px rgba(0, 0, 0, 0.25);
  overflow-y: scroll;
  padding: 15px;
  .save-btn {
    // background: #4f46ba;
    margin-right: 20px;
  }
  .action-btn {
    padding: 2px;
    min-width: 20px;
  }
  .width-msg-textfield {
    width: 100%;
  }
  .width-msg-textfield1 {
    width: 100%;
  }
  .width-group-textfield1 {
    width: 100%;
    input {
      padding: 0px 10px 0px 10px;
    }
  }

  .width-msg-colorfield {
    width: 70px;
  }
  .width-action {
    width: 50px;
  }
  .font-family-label {
    margin-left: -14px;
    margin-top: 7px;
  }
}
.group-slider {
  max-width: 1000px;
  .popup-subheading {
    font-size: 16px;
    margin-left: 10px;
    font-weight: 500;
    color: #9ea2a9;
  }
  .close-btn {
    padding: 0px;
    min-width: 10px;
  }
  .save-btn {
    // background: #4f46ba;
    margin-right: 10px;
  }
  .connected {
    color: #019f79;
    font-size: 12px;
    margin-left: 6px;
  }
  .notconnected {
    color: #fa5e5e;
    font-size: 12px;
    margin-left: 6px;
  }
  .select-msg {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #343b46;
    margin-top: 25px;
  }
  .input-msg {
    margin-top: 13px;
    width: 100%;
  }

  .icon-small {
    height: 20px;
    margin-left: -7px;
  }
  .msg-row {
    padding: 10px;
  }
  .last-update {
    margin-left: 7px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #9ea2a9;
  }
}
.icon-height {
  height: 15px !important;
  margin-left: -7px;
}
.map {
  position: relative !important;
}
.last-updates {
  margin-left: 7px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #9ea2a9;
}
.vms-marker {
  background: rgba(0, 0, 0);
  color: white;
  min-width: 100px;
  min-height: 50px;
  border-radius: 10px;
  padding: 5px;
  top: -9px;
  left: 46px;
}
.vms-marker ::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.progression_button {
  margin-right: 5px;
}
.arrow-icon {
  font-size: 10px;
  color: #4f46ba;
}
.vms-system-icon {
  width: 25px !important;
  height: 25px !important;
}
.set-map {
  height: 264px;
  border-radius: 10px !important;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  & > {
    border-radius: 10px;
  }
  .vmsMap {
    border-radius: 10px !important;
  }
}
.traffic-field {
  width: 280px;
  textarea,
  label,
  input {
    font-weight: 500;
    font-size: 14px;
    color: #7b818a;
  }
}
