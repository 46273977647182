$base-color: #333a44;

.card-display {
  display: flex;
}
.card-width {
  width: 50%;
}

.vids-devices-box {
  overflow-x: auto;
  // background: #eaedf2;
  border-radius: 10px;
  padding: 25px 25px;
  margin-top: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.02);
  .vsdsIconContainer {
    max-width: 90px;
    width: 90px;
    .messageBoard {
      background: $base-color;
      height: 32px;
      width: 100%;
      max-height: 32px;
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .screenMessage {
        padding: 0px 4px;
        font-size: 10px;
        line-height: 17px;
        font-weight: 400;
        color: #eaedf2;
      }
      .screenHooks {
        position: absolute;
        top: -5px;
        width: 100%;
        padding: 0px 5px;
        span {
          background: $base-color;
          height: 5px;
          width: 4px;
        }
      }
      .buttonBoard {
        display: block;
        width: 100%;
        height: 100%;
      }
      .down {
        bottom: -5px;
        top: initial !important;
      }
      .deviceHealth {
        width: 7px;
        right: -4px;
        height: 7px;
        position: absolute;
        top: -3px;
        border-radius: 50%;
      }
      .deviceHealthDown {
        bottom: -3px;
        top: initial !important;
      }
    }

    .boardStick {
      height: 54px;
      display: flex;
      justify-content: center;
      position: relative;
      align-items: flex-start;
      .stick {
        background: $base-color;
        width: 1px;
        height: 100%;
      }
      .pencil {
        position: absolute;
        padding: 0;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #7b818a;
      }
      .health-status {
        top: -1px;
        right: -6px;
        position: absolute;
        margin-right: 5px;
        width: 9px;
        height: 9px;
        border-radius: 50%;
      }
      .is-healthy {
        background: #019f79;
      }
      .is-unhealthy {
        background: #fa5e5e;
      }
    }
  }

  .packageTitle {
    width: 100%;
    text-align: center;
    font-size: 10px;
    line-height: 17px;
    font-weight: 500;
    padding: 4px;
    border: 1px solid;
    color: #343b46;
  }
  .roadContainer {
    margin: 5px 0px;
    .road {
      background-image: url('../assets/svg/VirtualMessagingSystem/RoadChunck.png');
      padding: 2px;
      background-repeat: repeat-x;
      text-align: center;
      p {
        background-color: #9ea2a9;
        padding: 0px 8px;
        display: inline;
        font-size: 15px;
        color: #fff;
      }
    }
    .roadSide {
      background-color: #333a44;
    }
    .side_LHS {
      padding-top: 15px;
      .packageTitle {
        border-bottom: none;
      }
    }
    .side_RHS {
      padding-bottom: 15px;
      .packageTitle {
        border-top: none;
      }
    }
  }
}
.vids-slider {
  .slider-head {
    margin: 10px 0px;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-transform: capitalize;
    color: #343b46;
  }
  .online-tag {
    align-items: center;
    display: flex;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    .online-status {
      margin-right: 5px;
      width: 9px;
      height: 9px;
      border-radius: 50%;
    }
  }
  .location-tag {
    margin: 10px 0px 2px 0px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }
  .location-sub-tag {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #9ea2a9;
  }
  .camera-tag {
    margin: 10px 0px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #343b46;
  }
  .camera-video {
    margin-right: 10px;
    border-radius: 10px;
    width: 30%;
    height: 100px;
  }
  .lpu-tag {
    margin-top: 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #343b46;
  }
  .lpu-sub-tag {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #9ea2a9;
  }
  .live-tag {
    color: #fa5e5e;
    align-items: center;
    display: flex;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    .live-status {
      margin-right: 5px;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: #fa5e5e;
    }
  }
  .table-row {
    border-top: 1px solid #eaedf2;
    border-bottom: 1px solid #eaedf2;
  }
  .table-col {
    border-left: 1px solid #eaedf2;
    border-right: 1px solid #eaedf2;
    font-weight: 500;
    font-size: 10px;
    line-height: 17px;
    color: #797d80;
  }
  .table-col-head {
    border-left: 1px solid #eaedf2;
    border-right: 1px solid #eaedf2;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #343b46;
  }
}

.vids-vdl {
  border-right: 1;
  border-color: 'divider';
  width: 25%;
  .vids-tabs {
    min-width: none;
    max-width: none;
    align-items: start;
    text-align: left;
  }
}

.vidl-green {
  color: #019f79;
}
.vidl-red {
  color: red;
}
.vidl-back-green {
  background-color: #019f79;
}
.vidl-back-red {
  background-color: red;
}

.vidl-image-button {
  z-index: 1;
  min-width: 10px;
  border-radius: 0px;
  font-size: 12px;
  font-weight: 500;
}
.vidl-live {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  align-self: center;
}
.display-tab {
  display: none;
}
.search-field {
  display: flex;
  max-height: 37px;
  input {
    font-size: 12px;
  }
}

.search-field1 {
  display: flex;
  justify-content: end;
  max-height: 37px;
  input {
    font-size: 12px;
  }
}

.video-online {
  position: absolute;
  z-index: 1;
  right: -4px;
  top: -4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.blink {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 20%;
  }
}

.widgetCards {
  text-align: center;
  .cardContent {
    height: 180px;
  }
}
.barchart {
  color: #9ea2a9;
  transform-origin: left;
  transform: translate(100%, 75%) rotate(-90deg);
}
.barchart1 {
  color: #9ea2a9;
}

.device-table {
  border-radius: 10px;
}
.legend-border {
  background: #ffffff;
  border: 1px solid #eaedf2;
  border-radius: 4px;
  padding: 4px;
}
.legend-icon {
  width: 16px;
  height: 16px;
  border-radius: 2px;
}
.system-link {
  position: absolute;
  border-radius: 4px;
  background: #f2e9ff;
  width: 28px;
  height: 28px;
  align-items: center;
  display: flex;
  justify-content: center;
  bottom: 17px;
  right: 22px;
  cursor: pointer;
  // color: #4f46ba;
}
.system-list {
  ul {
    column-count: 2;
    column-gap: 0px;
    row-gap: 0px;
    li {
      padding-bottom: 10px;
      display: inline-block;
      // width: 130px;
    }
  }
}
